/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /* -----TAB BOX FUNCTIONALITY----- */

        // update tab box content on tab click (desktop only) 
        function handleTabClick(e) {
          // assign tab clicked to variable 
          // for easy access
          var $el = $(this);

          // get id of content to show
          // check span parent in case 'tab-name' was clicked
          var idOfContent = $(e.target).data('toggle') || $(e.target).parent().data('toggle');

          // reset which tab is selected
          $('.tab a').attr('aria-selected', 'false');

          // if the selected element is the anchor tag, assign aria selected true to it,
          // if not, find the anchor then assign the aria
          if($el.is('a')) {
            // mark the current clicked tab as selected
            $el.attr('aria-selected', 'true');
          } else {
            $el.find('a').attr('aria-selected', true);
          }

          // hide previous tab from screen readers
          // remove 'active' class from current tab content
          // remove 'active' class from current tab 
          var $tabBox = $el.closest('.tab-box');
          $tabBox.find('.tab-box-contents .active').attr('aria-hidden', 'true');
          $tabBox.find('.tab-box-contents .active').removeClass('active');
          $tabBox.find('.tab-box-menu .active').removeClass('active');

          // add active class to tab that was clicked
          $el.addClass('active');

          // find tabContent by id and show it
          // show new tab to screen readers
          $tabBox.find('.tab-box-contents ' + idOfContent).addClass('active');
          $tabBox.find('.tab-box-contents ' + idOfContent).attr('aria-hidden', 'false');

          // select tab-equivalent on mobile
          $tabBox.find('.tab-box-menu .tab-box-select option[value="' + idOfContent +'"]').prop('selected', true);
        }
        $('.tab').on('click', handleTabClick);

        // update tab box content on select change (mobile only)
        function handleTabBoxSelectClick(e) {
          // assign tab clicked to variable 
          // for easy access
          var $el = $(this);

          // get value selected (which will be the id of the content)
          var optionValue = $(this).val();

          // remove 'active' class from current tab content
          // remove 'active' class from current tab 
          var $tabBox = $el.closest('.tab-box');
          $tabBox.find('.tab-box-contents .active').removeClass('active');
          $tabBox.find('.tab-box-menu .active').removeClass('active');

          // add active class to tab that was clicked
          $tabBox.find('.tab-box-menu .tab[data-toggle="' + optionValue +'"]').addClass('active');

          // add active class to corresponding content
          $tabBox.find('.tab-box-contents ' + optionValue).addClass('active');
        }  
        $('.tab-box-menu select').on('change', handleTabBoxSelectClick);

        /* ----- END TAB BOX FUNCTIONALITY----- */
        

        /* ----- MENU TOGGLE----- */
        function toggleNavBar() {
          $('.nav-primary').slideToggle(400);
        }
        $('#menu-toggle').click( toggleNavBar );
        /* ----- END MENU TOGGLE----- */

        /* ---- START OPEN/CLOSE SUB MENU ON HOVER ---- */

        // Open the sub menu on hover
        $('.menu-item-has-children').on('mouseenter', function(e) {
          // only run if on desktop
          if($(window).width() >= 1200) {
            // if any other submenus are open, close them
            $('.menu-item-has-children.open').removeClass('open');

            // add open class to menu item & set aria expanded to true for current menu
            $(this).attr('aria-expanded', 'true');
            $(this).addClass('open');
          }
        });

        // Close the sub menu when no longer hovering
        $('.menu-item-has-children').on('mouseleave', function(e) {
          // only run if on desktop
          if($(window).width() >= 1200) {
            const scope = this;

            // set aria expanded to false for current menu
            $(this).attr('aria-expanded', 'false');

            // add delay on closing the submenu (for ADA compliance & better UX)
            setTimeout(function() {
              $(scope).removeClass('open');
            }, 400);
          }
        });

        /* ---- END OPEN/CLOSE SUB MENU ON HOVER ---- */

        /* ---- START SUBMENU TOGGLE CLICK ---- */

        // open/close the sub menu on toggle click 
        function handleSubMenuToggleClick() {
          const scope = this;
          const $menuItemWithChildren = $(scope).closest('.menu-item-has-children');
          
          // add open class to menu toggle
          $(this).toggleClass('open');

          // if toggle has the class 'open',
          // open the submenu
          // if not,
          // close the submenu with delay
          if ($(this).hasClass('open')) {
            $menuItemWithChildren.attr('aria-expanded', 'true');
            $menuItemWithChildren.toggleClass('open');
          } else {
            $menuItemWithChildren.attr('aria-expanded', 'false');

            setTimeout(function() {
              $menuItemWithChildren.toggleClass('open');
            }, 300);
          }
        }

        // On submenu click, open menu
        $(document).on('click', '.sub-menu-toggle', handleSubMenuToggleClick);


        /* ---- END SUBMENU TOGGLE CLICK ---- */
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        /* ---- ADD SUBMENU TOGGLE ---- */

        // Insert submenu toggle before submenu
        $('.sub-menu').before('<a class="sub-menu-toggle" href="javascript:void(0);"><i class="icon icon-triangle-down"><span class="sr-only">Toggle Submenu</span></i></a>');

        // wrap nav list item links in a container
        $('.menu-item-has-children').each(function(){
          $(this).find('> a').wrapAll('<div class="menu-item-links-container" />');
        });

        /* ---- END ADD SUBMENU TOGGLE ---- */

        /* ---- START ADD ARIAS TO MENU ITEMS ---- */
        // Add arias (aria expanded, aria has popup) to menu items

        $('.menu-item-has-children').attr('aria-haspopup', 'true');
        $('.menu-item-has-children').attr('aria-expanded', 'false');
        /* ---- END ADD ARIAS TO MENU ITEMS ---- */
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page 
        
        /* ---- SEARCH OUR LIBRARY FORM HANDLER ---- */

        // When user completes "Search Our Library" form,
        // grab the scope and search term, combine them, and
        // redirect the user to the ebsco site
        $('.search-our-library-form').on('submit', function(e) {
          e.preventDefault();               // prevent default submit functionality

          var ebUrl = 'https://search.ebscohost.com/login.aspx?direct=true&site=eds-live&scope=site&type=0&authtype=guest&custid=s9001555&groupid=main';
          var scope = $('.ebscope').val();  // get scope
          var searchTerms = $('.ebterms').val();  // get search terms
          var query = '&bquery=';

          // if scope is KEY, don't prepend query with scope
          // else prepend with scope
          if(scope === 'KEY') {
            query += searchTerms;
          } else {
            query += scope + '+(' + searchTerms +')';
          }

          window.location.href = ebUrl + query; // redirect user

        });
        /* ---- END SEARCH OUR LIBRARY FORM HANDLER  ---- */

        /* ---- HOME MODULE CAROUSEL ---- */

        /*
         * Move to next slide (post) in the the carousel
         * @param {Event} e - the event
         */
         function handleCarouselNavItemClick(e) {
          e.preventDefault();

          var slides = $('.module-carousel .module-content');           // get all carousel slides
          var current = $('.module-content.active');                    // get the current slide
          var prevButtonPressed = $(this).hasClass('module-carousel-item-prev'); // check if the previous button was pressed
          var slideIndex = parseInt(current.data('slide-index')) - 1;   // get the slide index from the data attribute on the slide

          current.removeClass('active');                                // hide the current slide

          if(prevButtonPressed) {                                       // if the prev button was pressed
            slideIndex--;                                               // decremenet index

            if( !slides[slideIndex] ) {                                 // check if out of bounds
              slideIndex = slides.length - 1;                           // if yes, assign index of last slide
            }
          } else {                                                      // if prev not press, assume next was
            slideIndex++;                                               // increment index
            if( !slides[slideIndex] ) {                                 // check if out of bounds
              slideIndex = 0;                                           // if yes, go to start of carousel 
            }
          }

          current = $(slides[slideIndex]);                              // reassign current slide
          current.addClass('active');                                   // make it visible
        }

        $('.module-carousel-nav-item').on('click', handleCarouselNavItemClick);

        /* ---- END HOME MODULE CAROUSEL ---- */

        /* ---- START LIBRARY TRAFFIC ---- */

        const unavailableDiv = '<div class="availability-tracker-status unavailable"></div>';
        const inUseDiv = '<div class="availability-tracker-status in-use"></div>';
        const availableDiv = '<div class="availability-tracker-status available"></div>';

        /**
         * Render a div for each pc that is available/unavailable 
         */
         function _renderPcCounts(pcCounts) {
          // get jquery elements
          var $visualizationDiv = $('#availability-tracker-desktop .availability-tracker-visualization');
          var $numberAvailableDiv = $('#availability-tracker-desktop .number-available');
          var $numberInUseDiv = $('#availability-tracker-desktop .number-in-use');

          // store values in variables
          var pcTotal = parseInt(pcCounts.pcsTotal);
          var pcsAvailable = parseInt(pcCounts.pcsAvailable);
          var pcsInUse = parseInt(pcCounts.pcsInuse);
          var pcsUnavailable = parseInt(pcCounts.pcsUnavailable);

          // generate a div for every pc
          var pcsAvailableDiv = new Array(pcsAvailable + 1).join(availableDiv);
          var pcsInUseDiv = new Array(pcsInUse + 1).join(inUseDiv);
          var pcsUnavailableDiv = new Array(pcsUnavailable + 1).join(unavailableDiv);

          // add them to the tracker component
          $visualizationDiv.html(pcsAvailableDiv + pcsInUseDiv + pcsUnavailableDiv);

          // update numbers 
          $numberAvailableDiv.text(pcsAvailable);
          $numberInUseDiv.text(pcsInUse);
        }

        /**
         * Render a div for each laptop that is available/unavailable 
         */
         function _renderLaptopCounts(laptopCounts) {

          // get jquery elements
          var $visualizationDiv = $('#availability-tracker-laptop .availability-tracker-visualization');
          var $numberAvailableDiv = $('#availability-tracker-laptop .number-available');
          var $numberInUseDiv = $('#availability-tracker-laptop .number-in-use');

          // store values in variables
          var laptopTotal = parseInt(laptopCounts.laptopsTotal);
          var laptopsAvailable = parseInt(laptopCounts.laptopsAvailable);
          var laptopsInUse = parseInt(laptopCounts.laptopsInuse);
          var laptopsUnavailable = parseInt(laptopCounts.laptopsUnavailable);
          var laptopsCharging = parseInt(laptopCounts.laptopsCharging);

          // generate a div for every pc
          var laptopsAvailableDiv = new Array(laptopsAvailable + 1).join(availableDiv);
          var laptopsInUseDiv = new Array(laptopsInUse + 1).join(inUseDiv);
          var laptopsUnavailableDiv = new Array(laptopsUnavailable + 1).join(unavailableDiv);

          // add them to the tracker component
          $visualizationDiv.html(laptopsAvailableDiv + laptopsInUseDiv +laptopsUnavailableDiv);

          // update numbers 
          $numberAvailableDiv.text(laptopsAvailable);
          $numberInUseDiv.text(laptopsInUse + laptopsUnavailable + laptopsCharging);
        }

        /**
        * Make ajax request to fetch Laptop availability
        */
        function _fetchLaptopCounts() {
          // note: this endpoint returns JavaScript as a string. It is 
          // evaluated and the following global variables are created:
          // laptopcounts (array)
          // laptopstatus (array)
          $.ajax('/pctracker/counts/laptopcount.php')
          .done(function(expression) {
            // evaluate string expression
            // this creates the two global variables, noted above
            eval(expression);     

            /*
              laptopcounts looks like:
              [  
               {  
                  "laptopsTotal":32,
                  "laptopsAvailable":0,
                  "laptopsInuse":0,
                  "laptopsUnavailable":32,
                  "laptopsCharging":0,
                }
              ]
              */

              // if pccounts was created
              // render the data corresponding to it
              if (laptopcounts) {
                _renderLaptopCounts(laptopcounts[0]);
              }
            })
          .fail(function(error) {
            //eval('var laptopcounts = [ {"laptopsTotal":32, "laptopsAvailable":0, "laptopsCharging":0, "laptopsInuse":0, "laptopsUnavailable":32 } ];');
            //_renderLaptopCounts(laptopcounts[0]);
            $('#availability-tracker-laptop .availability-tracker-data').html('<div class="availability-tracker-error">An error has occurred while fetching laptop availability.</div>');
          });
        }

        function _fetchPcCounts() {
// note: this endpoint returns JavaScript as a string. It is 
          // evaluated and the following global variables are created:
          // pccounts (array)
          // pcstatus (array)
          $.ajax('/pctracker/counts/')
          .done(function(expression) {
            // evaluate string expression
            // this creates the two global variables, noted above
            eval(expression);     

            /*
              pccounts looks like:
              [  
               {  
                  "pcsTotal":80,
                  "pcsAvailable":79,
                  "pcsInuse":0,
                  "pcsUnavailable":1
                }
              ]

              pcstatus looks like:
              [
                {
                  "01": "available",
                  "02": "unAvailable",
                  .
                  .
                  .
                }
              ]
              */

              // if pccounts was created
              // render the data corresponding to it
              if (pccounts) {
                _renderPcCounts(pccounts[0]);
              }
            })
          .fail(function(error) {
            //eval('var pccounts = [ {"pcsTotal":80, "pcsAvailable":78,"pcsInuse":1,"pcsUnavailable":1} ]; var pcstatus = [{"01" : "available", "02" : "available", "03" : "available", "04" : "available", "05" : "available", "06" : "available", "07" : "available", "08" : "available", "09" : "available", "10" : "available", "11" : "available", "12" : "unAvailable", "13" : "available", "14" : "available", "15" : "available", "16" : "available", "17" : "available", "18" : "available", "19" : "available", "20" : "available", "21" : "available", "22" : "available", "23" : "available", "24" : "available", "25" : "available", "26" : "available", "27" : "available", "28" : "available", "29" : "available", "30" : "available", "31" : "available", "32" : "available", "33" : "available", "34" : "available", "35" : "available", "36" : "available", "37" : "available", "38" : "available", "39" : "available", "40" : "available", "41" : "available", "42" : "available", "43" : "available", "44" : "available", "45" : "available", "46" : "available", "47" : "available", "48" : "available", "49" : "available", "50" : "available", "51" : "available", "52" : "available", "53" : "available", "54" : "available", "55" : "available", "56" : "available", "57" : "available", "58" : "available", "59" : "available", "60" : "available", "61" : "available", "62" : "available", "63" : "available", "64" : "available", "65" : "available", "66" : "available", "67" : "available", "68" : "available", "69" : "available", "70" : "available", "71" : "available", "72" : "available", "73" : "available", "74" : "available", "75" : "available", "76" : "available", "77" : "available", "78" : "available", "79" : "available", "80" : "available" }];');
            //_renderPcCounts(pccounts[0]);
            $('#availability-tracker-desktop .availability-tracker-data').html('<div class="availability-tracker-error">An error has occurred while trying to fetch PC availability.</div>');
          });
        }

        /**
         * Open library traffic modal and fetch hours/pc-availability 
         * on Home Page 'More Information' click
         */
         function handleMoreInformationModalClick() {

          $('.library-traffic-modal').toggle('open');

          // if we haven't fetched the availability
          // data yet, fetch it
          if(!window.fetchedAvailabilityCounts) {
            _fetchPcCounts();
            _fetchLaptopCounts();
          }

          window.fetchedAvailabilityCounts = true;
        }

        $('.welcome .container').on('click', '.library-traffic-more-information, .modal-close-button a', handleMoreInformationModalClick);



        /* ---- END LIBRARY TRAFFIC ----- */

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        /* ---- START WELCOME TEXT HOURS ---- */
        // this is the text that states whether the library
        // is opened or closed today

        /**
        * Fetch today's hours from the WP Rest API
        * and update the welcome text appropriately
        */
        function fetchTodaysHours() {
          $.ajax('./wp-json/api/v1/library-hours?only_today=true')
          .done(function(todaysHours){
            todaysHours = todaysHours[0];
            // if library is closed, display the closed message
            if(todaysHours.open == 'Closed') {
              $('.library-hours-status').html('<span class="quick-dynamic-info" id="quick-hours">closed</span> today');
            } else {
              var hours = todaysHours.open + ' - ' + todaysHours.close;
              $('.library-hours-status').html('open today <span class="quick-dynamic-info" id="quick-hours">' + hours +'</span>');
            }

            // remove the hidden class and loader from the library hours text 
            $('.library-hours-text').removeClass('loader');
            $('.library-hours-text').removeClass('hide-text');
          })
          .fail(function() {
            // if current day failed to fetch,
            // display a link to the library hours
            $('.library-hours-text').html('<a class="library-traffic-more-information" href="javascript:void(0);">Library Hours</a>');
            $('.library-hours-text').removeClass('loader');
            $('.library-hours-text').removeClass('hide-text');
          });
        }
        fetchTodaysHours();
        /* ---- END WELCOME TEXT HOURS ---- */


        /* ---- START LIBRARY HOURS TABLE ---- */
        /**
        * Create a table row for a day's hours
        * @params {object} day - the hours for a day
        */
        function _renderLibraryHours(day) {
          var $tbody = $('.library-traffic-library-hours table tbody');

          if (day.open == 'Closed') {
            var td1 = '<td scope="row">' + day.dayow + '</td>';
            var td2 = '<td colspan="3">Library Closed</td>';
            $tbody.append('<tr>' + td1 + td2 + '</tr>');
          } else {
            var currentDay = '<td scope="row">' + day.dayow + '</td>';
            var libraryHours = '<td>' + day.open + ' - ' + day.close + '</td>';
            var researchDeskHours = '<td>' + day.refopen + ' - ' + day.refclose + '</td>';
            // if research desk open is 0, it's closed
            if (day.refopen == '0') {
              researchDeskHours = '<td>Closed</td>';
            }
            var lateNight = '<td>' + day.latenight + '</td>';

            $tbody.append('<tr>' + currentDay + libraryHours + researchDeskHours + lateNight + '</tr>');
          }
        }

        /**
        * Get the hours from the hoursdata endpoint
        * and then call the render function
        */
        function fetchLibraryHours() {
          $.ajax('./wp-json/api/v1/library-hours')
          .done(function(libraryHours) {

            // for every day (for the next 7 days),
            // render a <tr> with the hours
            for(var i = 0; i < 7; i++) {
              _renderLibraryHours(libraryHours[i]);
            }
          })
          .fail(function() {
            $('.library-traffic-library-hours table').html('<div class="library-hours-error">An error has occurred while fetching the library hours</div>');     
          });
        }

        fetchLibraryHours();
        /* ---- END LIBRARY HOURS TABLE ---- */
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
